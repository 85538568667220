import React from 'react';
import { Link, browserHistory } from 'react-router';
import { Message, Input, Divider, Button, Segment, Popup, Label, Container, Icon, } from 'semantic-ui-react';
import { TwitterPicker } from 'react-color';
import _ from 'lodash';
import moment from 'moment';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import Switch from 'react-switch';
import { inject, observer } from 'mobx-react';

import Form from '../../components/Form';
import BetaModal from '../../components/ui/Modal';
import BetaButton from '../../components/ui/Button';

import LabelEdit from '../Label/Edit';
import LabelAdd from '../Label/Add';

import { __ } from '../../i18n';
import * as utils from '../../utils';
import { zones } from '../../lib/zones';

const styles = {
  dayButton: {
    display: 'flex',
    width: 48,
    height: 48,
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 4,
    marginBottom: 0
  },
};

const languageOptions = () => [
  { text: __('English'), value: 'en', 'data-value': 'en' },
  { text: __('Spanish'), value: 'es', 'data-value': 'es' },
  { text: __('Portuguese'), value: 'pt', 'data-value': 'pt' }
];

const countryOptions = () => [
  { text: 'Brasil', value: 'br', 'data-value': 'br' },
  { text: 'United States', value: 'us', 'data-value': 'us' }
];

const typeOptions = () => [
  { text: __('School'), value: 'SCHOOL', 'data-value': 'school' },
  { text: __('Gym'), value: 'GYM', 'data-value': 'gym' },
  { text: __('Clinic'), value: 'CLINIC', 'data-value': 'clinic' },
  { text: __('Company'), value: 'COMPANY', 'data-value': 'company' },
  { text: __('Language School'), value: 'LANGUAGE_SCHOOL_ALL', 'data-value': 'language_school_all' },
  { text: __('Extracurricular'), value: 'EXTRACURRICULAR', 'data-value': 'extracurricular' },
  { text: __('Distance Education'), value: 'DE', 'data-value': 'de' },
  { text: __('Ballet'), value: 'BALLET', 'data-value': 'ballet' },
  { text: __('Soccer'), value: 'SOCCER', 'data-value': 'soccer' },
  { text: __('Music school'), value: 'MUSIC_SCHOOL', 'data-value': 'music_school' },
  { text: __('Technical course'), value: 'TECHNICAL_COURSE', 'data-value': 'technical_course' },
  { text: __('NGO'), value: 'NGO', 'data-value': 'ngo' },
  { text: __('Code'), value: 'CODE', 'data-value': 'code' }
];

const weekDays = () => [
  { text: __('Sunday'), value: 'Sunday', 'data-value': 'Sunday' },
  { text: __('Monday'), value: 'Monday', 'data-value': 'Monday' },
  { text: __('Tuesday'), value: 'Tuesday', 'data-value': 'Tuesday' },
  { text: __('Wednesday'), value: 'Wednesday', 'data-value': 'Wednesday' },
  { text: __('Thursday'), value: 'Thursday', 'data-value': 'Thursday' },
  { text: __('Friday'), value: 'Friday', 'data-value': 'Friday' },
  { text: __('Saturday'), value: 'Saturday', 'data-value': 'Saturday' },
];

const timeOptions = has59 => ({
  hour: ['07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18',
    '19', '20', '21', '22', '23', '00', '01', '02', '03', '04', '05', '06'].map(v => ({ text: v, value: v })),
  minute: (has59 ? ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55', '59'] : ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55']).map(v => ({ text: v, value: v }))
});

const functionalityOptions = (organization, settings, props, studentRepliesFeature = false) => [
  {
    condition: true,
    text: __('General functionality'),
    values: [
      { text: __('Messages can be sent'), value: 'confMessage', enabled: true },
      { text: __('Media can be sent'), value: 'confMedia', enabled: settings.confMessage },
      { text: __('Commitments can be sent'), value: 'confCommitment', enabled: settings.confMessage },
      { text: __('Surveys can be sent'), value: 'confSurvey', enabled: settings.confMessage },
      // { text: __('Charges can be sent'), value: 'confCharge', enabled: settings.confMessage },
      { text: __('Reports can be sent'), value: 'confReport', enabled: settings.confMessage },
    ]
  }, {
    condition: true,
    className: 'row',
    text: utils.checkIfItIsClientOrSchool(organization.type) === 1 ? __('Student/Parent functionality') : __('Client functionality'),
    values: [
      { text: utils.checkIfItIsClientOrSchool(organization.type) === 1 ? __('Parent/student can send messages') : __('Clients can send message'), value: 'confMessageStudent', enabled: settings.confMessage, className: 'teste' },
      {
        text: utils.checkIfItIsClientOrSchool(organization.type) === 1 ? __('Parent/student can reply') : __('Clients can reply'),
        value: 'confReplyStudent',
        className: 'teste',
        enabled: true,
        onClick: (e) => {
          e.onInputChange(null, { name: 'confReplyStudent', checked: !e.state.values.confReplyStudent });
          if (studentRepliesFeature && !e.state.values.confReplyStudent) {
            e.onInputChange(null, { name: 'confRepliesStudent', checked: true });
          }
        },
        components: (studentRepliesFeature) ? [
          {
            type: 'GROUP',
            subcomponents: [
              {
                type: 'CHECKBOX',
                text: utils.checkIfItIsClientOrSchool(organization.type) === 1 ? __('Do not allow successive replies from guardians and students') : __('Do not allow successive replies for clients'),
                value: 'confRepliesStudent',
                disabled: !settings.confReplyStudent,
                disableWithoutBanIcon: !settings.confReplyStudent,
                inverted: true
              }
            ]
          }
        ] : null
      },
      { text: utils.checkIfItIsClientOrSchool(organization.type) === 1 ? __('Parent/student can send media') : __('Clients can send media'), value: 'confMediaStudent', enabled: settings.confMessage && settings.confMedia, className: 'teste' },
      {
        condition: studentRepliesFeature && !settings.confRepliesStudent,
        type: 'LABEL',
        styles: { backgroundColor: '#FAFAFA', borderRadius: '10px', padding: '10px', alignItems: 'center' },
        icon: {
          name: 'info circle',
          style: { color: 'gray', fontSize: '22px', fontWeight: 'bold', marginRight: '10px' }
        },
        text: utils.parseFormattedTranslation({
          text: utils.checkIfItIsClientOrSchool(organization.type) === 1 ?
            __('By checking the "**Do not allow successive replies from guardians and students**" option, your organization retains the old ClassApp experience, in which parents and students can only reply after they have been replied to by a staff member or administrator.')
            : __('By checking the "**Do not allow successive replies from clients**" option, your organization retains the old ClassApp experience, in which clients can only reply after they have been replied to by a staff member or administrator.'),
          Wrapper: ({ children }) => <b>{children}</b>
        }),
      }
    ]
  }, {
    condition: true,
    className: 'row',
    text: __('Staff functionality'),
    values: [
      { text: __('Staff can send messages'), value: 'confMessageStaff', enabled: settings.confMessage },
      { text: __('Show staff list'), value: 'confAdminList', enabled: settings.confMessage }
    ]
  }, {
    condition: organization.features && organization.features.doNotDisturb,
    className: 'row',
    text: __('Notification settings'),
    values: [
      {
        text: __('Customize when notifying users in the organization'),
        value: 'customNotification',
        enabled: true,
        checked: (settings.ntfStartTime && settings.ntfEndTime),
        onClick: (e) => {
          if (settings.ntfStartTime && settings.ntfEndTime) {
            e.onInputChange(null, { name: 'ntfStartTime', value: null });
            e.onInputChange(null, { name: 'ntfEndTime', value: null });
            e.onInputChange(null, { name: 'ntfWeekDaysArray', value: [] });
          } else {
            e.onInputChange(null, { name: 'ntfStartTime', value: '08:00:00' });
            e.onInputChange(null, { name: 'ntfEndTime', value: '18:00:00' });
            e.onInputChange(null, { name: 'ntfWeekDaysArray', value: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'] });
          }
        },
      },
    ],
    customComponent: {
      condition: settings.ntfStartTime && settings.ntfEndTime,
      components: [
        {
          type: 'GROUP',
          styles: { display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: '0px', paddingTop: '16px' },
          subcomponents: [
            {
              type: 'DROPDOWN',
              name: 'startHour',
              placeholder: '--',
              value: e => e.getTime('hour', 'start'),
              disabled: (!settings.ntfStartTime && !settings.ntfEndTime) || (settings.ntfStartTime === '00:00:00' && settings.ntfEndTime === '23:59:59'),
              options: timeOptions().hour,
              onChange: (e, { value }) => {
                e.setTime('hour', value, 'start', () => {
                  if (moment(settings.ntfEndTime, 'hh:mm:ss').isBefore(moment(settings.ntfStartTime, 'hh:mm:ss'))) {
                    alert(__('You can not set an opening time to start after the end time.'));
                    e.setValue('ntfEndTime', settings.ntfStartTime);
                  }
                });
              },
              props: {
                upward: true
              }
            },
            {
              type: 'DROPDOWN',
              name: 'startMinute',
              placeholder: '--',
              value: e => e.getTime('minute', 'start'),
              disabled: (!settings.ntfStartTime && !settings.ntfEndTime) || (settings.ntfStartTime === '00:00:00' && settings.ntfEndTime === '23:59:59'),
              options: timeOptions(settings.ntfEndTime === '23:59:59').minute,
              onChange: (e, { value }) => {
                e.setTime('minute', value, 'start', () => {
                  if (moment(settings.ntfEndTime, 'hh:mm:ss').isBefore(moment(settings.ntfStartTime, 'hh:mm:ss'))) {
                    alert(__('You can not set an opening time to start after the end time.'));
                    e.setValue('ntfEndTime', settings.ntfStartTime);
                  }
                });
              },
              props: {
                upward: true
              }
            },
            {
              type: 'TEXT',
              text: __('until')
            },
            {
              type: 'DROPDOWN',
              name: 'endHour',
              placeholder: '--',
              value: e => e.getTime('hour', 'end'),
              disabled: (!settings.ntfStartTime && !settings.ntfEndTime) || (settings.ntfStartTime === '00:00:00' && settings.ntfEndTime === '23:59:59'),
              options: timeOptions().hour,
              onChange: (e, { value }) => {
                e.setTime('hour', value, 'end', () => {
                  if (moment(settings.ntfStartTime, 'hh:mm:ss').isAfter(moment(settings.ntfEndTime, 'hh:mm:ss'))) {
                    alert(__('You can not set an end time to end before the start time.'));
                    e.setValue('ntfStartTime', settings.ntfEndTime);
                  }
                });
              },
              props: {
                upward: true
              }
            },
            {
              type: 'DROPDOWN',
              name: 'endMinute',
              placeholder: '--',
              value: e => e.getTime('minute', 'end'),
              disabled: (!settings.ntfStartTime && !settings.ntfEndTime) || (settings.ntfStartTime === '00:00:00' && settings.ntfEndTime === '23:59:59'),
              options: timeOptions(settings.ntfEndTime === '23:59:59').minute,
              onChange: (e, { value }) => {
                e.setTime('minute', value, 'end', () => {
                  if (moment(settings.ntfStartTime, 'hh:mm:ss').isAfter(moment(settings.ntfEndTime, 'hh:mm:ss'))) {
                    alert(__('You can not set an end time to end before the start time.'));
                    e.setValue('ntfStartTime', settings.ntfEndTime);
                  }
                });
              },
              props: {
                upward: true
              }
            },
            {
              type: 'CHECKBOX',
              text: __('All day'),
              value: 'allDay',
              enabled: true,
              checked: (settings.ntfStartTime === '00:00:00' && settings.ntfEndTime === '23:59:59'),
              onClick: (e) => {
                if (settings.ntfStartTime === '00:00:00' && settings.ntfEndTime === '23:59:59') {
                  e.onInputChange(null, { name: 'ntfStartTime', value: '08:00:00' });
                  e.onInputChange(null, { name: 'ntfEndTime', value: '18:00:00' });
                } else {
                  e.onInputChange(null, { name: 'ntfStartTime', value: '00:00:00' });
                  e.onInputChange(null, { name: 'ntfEndTime', value: '23:59:59' });
                }
              },
            }
          ]
        },
        {
          type: 'DIV',
          styles: { display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 16, marginBottom: 0 },
          subcomponents: weekDays().map(day =>
          ({
            type: 'BUTTON',
            disabled: !settings.ntfStartTime && !settings.ntfEndTime,
            text: day.text,
            styles: {
              ...styles.dayButton,
              backgroundColor: settings.ntfWeekDaysArray.includes(day.value) ? '#0080FF' : '#fff',
              border: settings.ntfWeekDaysArray.includes(day.value) ? null : 'solid 1px #e9ecef',
            },
            onClick: e => e.toggleDay(day.value),
            linkStyles: { fontSize: 13, color: settings.ntfWeekDaysArray.includes(day.value) ? '#fff' : '#212529' }
          })
          )
        }
      ]
    }
  }, {
    condition: props.user && props.user.isMaster,
    className: 'row',
    text: __('Recurrence settings'),
    values: [
      {
        text: __('Recurrence module available'),
        value: 'recurrenceAvailable',
        enabled: true,
        checked: settings.confRecurrence,
        onClick: (e) => {
          if (settings.confRecurrence) {
            e.onInputChange(null, { name: 'confRecurrence', value: false });
          } else {
            e.onInputChange(null, { name: 'confRecurrence', value: true });
          }
        }
      }
    ]
  }, {
    condition: props.user && props.user.isMaster && props.add,
    text: __('Create organization settings (master only)'),
    values: [
      {
        text: __('Create default features'),
        value: 'defaultFeatures',
        enabled: true,
        onClick: (e) => {
          if (!e.state.values.defaultFeatures) {
            e.onInputChange(null, { name: 'defaultFeatures', value: ['TAGS', 'REPORTS', 'LABELS', 'FORMS'] });
          } else {
            e.onInputChange(null, { name: 'defaultFeatures', value: null });
          }
        }
      }
    ]
  }
];

const planOptions = [
  { text: __('Premium'), value: 'PREMIUM', 'data-value': 'premium' },
  { text: __('Standard'), value: 'STANDARD', 'data-value': 'standard' },
  { text: __('Light'), value: 'LIGHT', 'data-value': 'light' },
  { text: __('Free'), value: 'FREE', 'data-value': 'free' },
  { text: __('Light new'), value: 'LIGHT_NEW', 'data-value': 'light_new' },
  { text: __('Standard new'), value: 'STANDARD_NEW', 'data-value': 'standard_new' }
];

const usageOptions = [
  { text: __('Test'), value: 'TEST', 'data-value': 'test' },
  { text: __('Client'), value: 'CLIENT', 'data-value': 'client' },
  { text: __('Former Client'), value: 'FORMER_CLIENT', 'data-value': 'former_client' },
  { text: __('Sandbox'), value: 'SANDBOX', 'data-value': 'sandbox' },
  { text: __('Simulation'), value: 'SIMULATION', 'data-value': 'simulation' },
  { text: __('Free Trial'), value: 'TRIAL', 'data-value': 'trial' }
];

const CREATE_ORGANIZATION_BANNER = gql`
  mutation CreateOrganizationBanner($CreateOrganizationBannerInput: CreateOrganizationBannerInput!) {
    createOrganizationBanner(input: $CreateOrganizationBannerInput) {
      organizationBanner {
        id
        organizationId
        bannerType
      }
    }
  }
`;

const DELETE_ORGANIZATION_BANNER = gql`
  mutation DeleteOrganizationBanner($DeleteOrganizationBannerInput: DeleteOrganizationBannerInput!) {
    deleteOrganizationBanner(input: $DeleteOrganizationBannerInput) {
      organizationBanner {
        id
        organizationId
        bannerType
      }
    }
  }
`;

@graphql(gql`mutation enableProductFeature($id: Int!, $identifier: String!){
    enableProductFeature(input: { identifier: $identifier, organizationId: $id }) {
      enableSuccess
    }
  }
`, {
  options: {
    refetchQueries: ['AppQuery', 'OrganizationNodeIdQuery', 'OrganizationEditMasterQuery']
  },
  name: 'enableProductFeatureMutation'
})

@graphql(gql`mutation disableProductFeature($id: Int!, $identifier: String!){
    disableProductFeature(input: { identifier: $identifier, organizationId: $id }) {
      disableSuccess
    }
  }
`, {
  options: {
    refetchQueries: ['AppQuery', 'OrganizationNodeIdQuery', 'OrganizationEditMasterQuery']
  },
  name: 'disableProductFeatureMutation'
})

@inject('store', 'client') @observer
export default class OrganizationForm extends Form {
  defaultValues = {
    fullname: '',
    username: '',
    link: '',
    color: '',
    language: 'pt',
    country: '',
    confMessage: true,
    confMedia: true,
    confCommitment: true,
    confSurvey: true,
    confCharge: true,
    confReport: true,
    confMessageStudent: true,
    confReplyStudent: true,
    confRepliesStudent: true,
    confMediaStudent: true,
    confMessageStaff: true,
    confAdminList: true,
    confRecurrence: false,
    ntfStartTime: null,
    ntfEndTime: null,
    ntfWeekDaysArray: [],
    type: 'SCHOOL',
    plan: 'PREMIUM',
    collectionRule: [],
    defaultFeatures: ['TAGS', 'REPORTS', 'LABELS', 'FORMS'],
    labels: [],
    timezone: 'America/Sao_Paulo',
    usage: 'CLIENT',
    consultantId: null
  }

  constructor(props) {
    super(props);
    this.state = {
      isOverdueDisabled: false
    };
    this.studentRepliesFeature = (this.props.store.unleash && this.props.store.unleash.getStatus('student-replies'));
  }

  rules = {
    username: 'required',
    fullname: ['required', value => value.trim().length > 0],
    type: 'required',
    link: 'url'
  }

  openLabelForm = (type = 'add', label) => {
    if (type === 'edit') {
      this.props.store.appends.push(
        <LabelEdit label={label} />
      );
    } else {
      this.props.store.appends.push(
        <LabelAdd organization_id={this.props.organization.id} />
      );
    }
  }

  renderTags = () => {
    const { organization, user } = this.props;

    return organization &&
      organization.tags.map(tag =>
        <Label
          size="large"
          style={{ marginRight: 5, marginBottom: 5 }}
          key={tag.name}
          as={this.props.store.currentEntity.type === 'ADMIN' && 'a'}
        >
          {
            this.props.store.currentEntity.type === 'ADMIN' ?
              <span id={tag.name} onClick={() => browserHistory.push(`organizations/${organization.id}/edit/tags/${tag.id}`)}>
                <Icon data-action="edit-tag" data-params={tag.id} name="pencil" />
                {tag.name}
              </span>
              :
              <span id={tag.name}>
                {tag.name}
              </span>
          }
          {
            user && user.isMaster ?
              <Icon data-action="delete-tag" style={{ marginLeft: '10px' }} id={`delete-${tag.name}`} data-params={tag.id} name="delete" onClick={() => browserHistory.push(`organizations/${organization.id}/edit/tags/${tag.id}/delete`)} />
              :
              null
          }
        </Label>
      );
  }

  renderLabels = () => {
    const { labels } = this.props.organization;
    const { store } = this.props;

    const canUpdateOrganization = store.currentOrganization && store.currentOrganization.permissions &&
      store.currentOrganization.permissions.entityScopes && store.currentOrganization.permissions.entityScopes.includes('UPDATE_ORGANIZATION');

    const canCreateLabels = (store.currentEntity.type === 'ADMIN' || canUpdateOrganization);

    const isMaster = store.currentUser && store.currentUser.isMaster;

    return labels.map((label, key) => {
      if (label.type === 'INTEGRATION') {
        return (
          <Popup
            trigger={
              <Label
                size="large"
                style={{ marginRight: 5, marginBottom: 5 }}
                className={isMaster ? 'pointer' : null}
                key={`${key}-${label.title}`}
                onClick={() => (isMaster ? this.openLabelForm('edit', label) : null)}
              >
                <span id={label.title}>
                  <Icon name={this.studentRepliesFeature ? 'circle' : 'square'} style={{ fontWeight: 'bold', color: `#${label.color}` }} />
                  {label.title}
                </span>
                {isMaster ? <Icon data-action="edit-label" data-params={label.id} name="pencil" style={{ margin: '0px 0px 0px 18px' }} /> : null}
              </Label>
            }
            content={isMaster ? __('This label is linked to an integration module') : __('This label cannot be changed as it is linked to an integration module')}
          />
        );
      }

      return (
        <Label
          size="large"
          style={{ marginRight: 5, marginBottom: 5 }}
          className={canCreateLabels && 'pointer'}
          key={`${key}-${label.title}`}
          onClick={() => (canCreateLabels ? this.openLabelForm('edit', label) : null)}
        >
          <span id={label.title}>
            <Icon name={this.studentRepliesFeature ? 'circle' : 'square'} style={{ fontWeight: 'bold', color: `#${label.color}` }} />
            {label.title}
          </span>
          {
            canCreateLabels &&
            <Icon data-action="edit-label" data-params={label.id} name="pencil" style={{ margin: '0px 0px 0px 18px' }} />
          }
        </Label>
      );
    });
  }

  onInputChangeSpecialCharacters = (e, { name, value, checked }) => {
    value = this.filterSpecialCharacters(value);
    this.onInputChange(e, { name, value, checked });
  }

  filterSpecialCharacters = str => str.replace(/[^\w\d]/g, '')

  beforeSubmit = (values) => {
    if (values.link && values.link.search('://') === -1) {
      values.link = 'http://' + values.link;
    }

    return values;
  }

  getTime = (where, name) => {
    const time = name === 'end' ? this.state.values.ntfEndTime : this.state.values.ntfStartTime;
    return (time) ? time.split(':')[where === 'hour' ? 0 : 1] : '';
  }

  setTime = (where = 'hour', value, name, cb) => {
    const time = (name === 'end' ? this.state.values.ntfEndTime : this.state.values.ntfStartTime) || '00:00';
    const split = time.split(':');
    const hour = split[0];
    const minute = split[1];

    if (where === 'hour') {
      this.setValue(name === 'end' ? 'ntfEndTime' : 'ntfStartTime', `${value}:${minute}`, cb);
    } else if (where === 'minute') {
      this.setValue(name === 'end' ? 'ntfEndTime' : 'ntfStartTime', `${hour}:${value}`, cb);
    }
  }

  toggleDay = (day) => {
    const { ntfWeekDaysArray } = this.state.values;
    const nwda = _.cloneDeep(ntfWeekDaysArray);
    const selectedDay = nwda.includes(day);
    if (selectedDay) {
      nwda.splice(nwda.indexOf(day), 1);
    } else {
      nwda.push(day);
    }
    this.setState({
      values: {
        ...this.state.values,
        ntfWeekDaysArray: nwda
      }
    });
  }

  // eslint-disable-next-line no-undef
  handleOverdueBannerModal = (isOverdue) => {
    if (!isOverdue) {
      this.props.store.appends.push(
        <BetaModal
          id="InfoModal"
          onClose={() => { this.props.store.appends.pop(); }}
          actions={[
            <BetaButton
              data-action="cancel"
              transparent round
              text={__('Cancel')}
              style={{ marginLeft: 0, marginRight: 'auto' }}
              onClick={() => {
                this.props.store.appends.pop();
              }}
            />,
            <BetaButton
              data-action="submit"
              round
              text={__('Yes, I want to enable')}
              style={{ marginRight: 0 }}
              onClick={() => {
                this.handleOverdueBanner(isOverdue);
                this.props.store.appends.pop();
              }}
              primary
            />
          ]}
          header={__('Do you want to enable this banner?')}
          content={
            <div>
              <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>{__('“Default Banner” will be displayed to organization administrators')}</span>
            </div>
          }
        />
      );
    } else {
      this.handleOverdueBanner(isOverdue);
    }
  }

  // eslint-disable-next-line no-undef
  handleOverdueBanner = (isOverdue) => {
    try {
      const { client, organization } = this.props;
      this.setState({ isOverdueDisabled: true });

      if (isOverdue) {
        const findFinancialBanner = organization.organizationBanners && organization.organizationBanners.nodes && organization.organizationBanners.nodes.find(__banner => __banner.bannerType === 'FINANCIAL_SUSPENDED');

        if (findFinancialBanner) {
          client.mutate({
            mutation: DELETE_ORGANIZATION_BANNER,
            variables: {
              DeleteOrganizationBannerInput: {
                id: parseInt(findFinancialBanner.id, 10)
              }
            }
          });

          window.location.replace(`/organizations/${organization.id}/edit`);
        }
      } else {
        client.mutate({
          mutation: CREATE_ORGANIZATION_BANNER,
          variables: {
            CreateOrganizationBannerInput: {
              organizationId: organization && parseInt(organization.id, 10),
              bannerType: 'FINANCIAL_SUSPENDED'
            }
          }
        });

        window.location.replace(`/organizations/${organization.id}/edit`);
      }
    } catch (e) {
      console.error(e);
    }
  }

  handleArrivedModal(isEnabled) {
    this.props.store.appends.push(
      <BetaModal
        id="InfoModal"
        onClose={() => { this.props.store.appends.pop(); }}
        actions={[
          <BetaButton
            data-action="cancel"
            transparent round
            text={__('Cancel')}
            style={{ marginLeft: 0, marginRight: 'auto' }}
            onClick={() => {
              this.props.store.appends.pop();
            }}
          />,
          <BetaButton
            data-action="submit"
            round
            text={isEnabled ? __('Yes, I want to disable') : __('Yes, I want to enable')}
            style={{ marginRight: 0 }}
            onClick={() => {
              this.handleArrived(isEnabled);
              this.props.store.appends.pop();
            }}
            primary
          />
        ]}
        header={isEnabled ? __('Do you want to disable "Arrived?"') : __('Do you want to enable "Arrived?"')}
        content={
          <div>
            <span style={{ fontSize: '1.143rem', fontWeight: 400 }}>
              {
                isEnabled ? __('The feature "Arrived" will be disabled for this organization')
                  : __('The feature "Arrived" will be available for this organization')
              }
            </span>
          </div>
        }
      />
    );
  }

  handleArrived(isEnabled) {
    try {
      const { organization, enableProductFeatureMutation, disableProductFeatureMutation } = this.props;
      const mutationVariables = {
        id: organization.id,
        identifier: 'arrived'
      };

      if (isEnabled) {
        disableProductFeatureMutation({
          variables: mutationVariables,
        });
      } else {
        enableProductFeatureMutation({
          variables: mutationVariables,
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  showNewSectionStyle = (divider, section, button, divProps = {}) => {
    if (this.studentRepliesFeature) {
      return (
        <div {...divProps}>
          <Divider section />
          {divider}
          {button}
          {section}
        </div>
      );
    }
    return (
      <div>
        {divider}
        {button}
        {section}
      </div>
    );
  }

  /**
   * @returns {JSX.Element}
   */
  showNewColorPlanStyle = (colorChild, planChild) => {
    if (this.studentRepliesFeature && planChild) {
      return (
        <Form.Field className="field-group">
          {colorChild}
          {planChild}
        </Form.Field>
      );
    }
    return [colorChild, planChild];
  }

  render() {
    const { values, errors, isOverdueDisabled } = this.state;
    const { organization, user, store, add } = this.props;

    const organizationBanners = (organization && organization.organizationBanners && organization.organizationBanners.nodes) || [];
    const hasOrganizationBanners = organizationBanners && organizationBanners.find(__banner => __banner.bannerType === 'FINANCIAL_SUSPENDED');

    const isOverdue = !!(hasOrganizationBanners);

    const isArrivedEnabled = store.currentOrganization && store.currentOrganization.productFeatures && store.currentOrganization.productFeatures.nodes && store.currentOrganization.productFeatures.nodes.some(feature => feature.identifier === 'arrived');

    const canCreateLabels = (store.currentUser.isMaster || store.currentEntity.type === 'ADMIN' ||
      (store.currentOrganization && store.currentOrganization.permissions && store.currentOrganization.permissions.entityScopes && store.currentOrganization.permissions.entityScopes.includes('UPDATE_ORGANIZATION')));

    const isShowMasterSettings = (user && user.isMaster) && !add;

    return (
      <Form id="OrganizationForm" {...this.props} onSubmit={this.handleSubmit}>
        {isShowMasterSettings &&
          <div>
            <Divider section horizontal>{__('Master Settings')}</Divider>
            <Segment className={this.studentRepliesFeature ? 'new' : ''}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Switch
                  checked={isOverdue}
                  onChange={() => this.handleOverdueBannerModal(isOverdue)}
                  onColor="#084FFF"
                  onHandleColor="#FFFFFF"
                  offColor="#E7E7E7"
                  handleDiameter={29}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  disabled={isOverdueDisabled}
                  height={36}
                  width={67}
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '9px' }}>
                  <span>{__('Overdue banner')}</span>
                  <span style={{ color: '#666666' }}>{isOverdue ? __('Enabled') : __('Disabled')}</span>
                </div>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginTop: this.studentRepliesFeature ? '' : '1rem' }}>
                <Switch
                  checked={isArrivedEnabled}
                  onChange={() => this.handleArrivedModal(isArrivedEnabled)}
                  onColor="#084FFF"
                  onHandleColor="#FFFFFF"
                  offColor="#E7E7E7"
                  handleDiameter={29}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  // disabled={isArrivedDisabled}
                  height={36}
                  width={67}
                />
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '9px' }}>
                  <span>{__('Arrived')}</span>
                  <span style={{ color: '#666666' }}>{isArrivedEnabled ? __('Enabled') : __('Disabled')}</span>
                </div>
              </div>
            </Segment>
          </div>}
        <Divider section horizontal>{__('Basic information')}</Divider>
        <Segment
          className={this.studentRepliesFeature ? 'new org-info' : ''}
          style={{ zIndex: 1 }}
        >
          <Form.Group widths="equal">
            <Form.Input
              label={__('Name')}
              placeholder={__('Name')}
              name="fullname"
              value={values.fullname || ''}
              onChange={this.onInputChange}
              error={errors && errors.fullname}
            />
            {
              errors && errors.fullname ?
                <div style={{ marginTop: 24 }}>
                  <Popup
                    trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                    content={__('Organization name cannot be empty')}
                  />
                </div>
                :
                null
            }

            <Form.Field>
              <label htmlFor="username">{__('Username')}</label>
              <Input
                label="classapp.co/"
                placeholder={__('Username')}
                name="username"
                value={values.username || ''}
                onChange={this.onInputChangeSpecialCharacters}
                error={errors && errors.username}
              />
            </Form.Field>
            {
              errors && errors.username ?
                <div style={{ marginTop: 24 }}>
                  <Popup
                    trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                    content={__('Username cannot be empty')}
                  />
                </div>
                :
                null
            }
          </Form.Group>

          <Form.Group widths="equal">
            <Form.Input
              label={__('Link')}
              placeholder={__('Link')}
              name="link"
              value={values.link || ''}
              onChange={this.onInputChange}
            />
            {
              errors && errors.link ?
                <div style={{ marginTop: 24 }}>
                  <Popup
                    trigger={<Button type="button" icon="exclamation triangle" basic color="red" />}
                    content={__('Link cannot be empty')}
                  />
                </div>
                :
                null
            }

            {this.showNewColorPlanStyle(
              <Form.Field>
                <label htmlFor="color">{__('Theme color')}</label>
                <Popup
                  on="click"
                  position="top right"
                  trigger={
                    <Button
                      secondary as="span" icon="paint brush" data-name="theme-color" style={{ backgroundColor: '#' + values.color }}
                      onClick={() => this.setState({ pickColor: !this.state.pickColor })}
                    />
                  }
                  content={
                    <TwitterPicker
                      triangle="hide"
                      color={values.color || undefined}
                      onChange={color => this.setState({ values: { ...values, color: color.hex.slice(1) } })}
                    />
                  }
                />
              </Form.Field>,
              user && user.isMaster && <Form.Dropdown
                label={__('Plan')}
                name="plan"
                selection
                data-value={values.plan}
                value={values.plan}
                placeholder={__('Select type')}
                options={planOptions}
                onChange={this.onSelectionChange}
              />
            )}
          </Form.Group>
          <Form.Group widths="equal">
            <Popup
              trigger={<Form.Dropdown
                label={__('Language')}
                name="language"
                selection
                data-value={values.language}
                value={values.language}
                placeholder={__('Select language')}
                options={languageOptions()}
                onChange={this.onSelectionChange}
              />}
              content={<span>{__('This field does not affect the system language, it only affects the invitations language')}</span>}
              hideOnScroll
            />

            <Form.Dropdown
              label={__('Country')}
              name="country"
              selection
              data-value={values.country}
              value={values.country}
              placeholder={__('Select country')}
              options={countryOptions()}
              onChange={this.onSelectionChange}
            />
            <Form.Dropdown
              label={__('Type')}
              name="type"
              selection
              data-value={values.type}
              value={values.type}
              placeholder={__('Select type')}
              options={typeOptions()}
              onChange={this.onSelectionChange}
            />
          </Form.Group>

          {
            user && user.isMaster && <Form.Group widths="equal">
              <Form.Dropdown
                label={__('Timezone')}
                name="timezone"
                selection
                search
                data-value={values.timezone}
                value={values.timezone}
                placeholder={__('Select timezone')}
                options={zones().map(zone => ({ text: zone, value: zone }))}
                onChange={this.onSelectionChange}
              />
              <Form.Dropdown
                label={__('Client type')}
                name="usage"
                selection
                data-value={values.usage}
                value={values.usage}
                placeholder={__('Select client type')}
                options={usageOptions}
                onChange={this.onSelectionChange}
              />
              <Form.Dropdown
                label={__('Consultant')}
                name="consultantId"
                selection
                search
                data-value={values.consultantId}
                value={values.consultantId}
                placeholder={__('Select consultant')}
                options={this.props.channels}
                onChange={this.onSelectionChange}
              />
            </Form.Group>
          }

        </Segment>

        {organization &&
          <div>
            <Divider section horizontal>{__('Tags')}</Divider>
            {
              user && user.isMaster ?
                <Container textAlign="center" className={(this.studentRepliesFeature ? 'new ' : '')}>
                  <Button
                    // style={{ marginTop: '15px' }}
                    icon="plus" as={Link}
                    content={__('Add tag')}
                    to={`/organizations/${organization.id}/edit/tags/add`}
                  />
                </Container>
                :
                <Message warning>
                  {__('Contact our support to add or delete tags.')}
                </Message>
            }
            <Segment className={this.studentRepliesFeature ? 'new label-content' : ''}>
              {this.renderTags()}
            </Segment>
          </div>
        }
        {organization && organization.features && organization.features.labels && ((organization.labels && organization.labels.length) || store.currentEntity.type === 'ADMIN') &&
          this.showNewSectionStyle(
            <Divider section horizontal>{__('Labels Configuration')}</Divider>,
            <Segment className={this.studentRepliesFeature ? 'new label-content' : ''}>
              {this.renderLabels()}
            </Segment>,
            canCreateLabels &&
            <Label
              size="large"
              style={{ marginRight: 5, marginBottom: 5 }}
              className={`pointer add-button ${(this.studentRepliesFeature ? 'new ' : '')}`}
              onClick={this.openLabelForm}
            >
              <span>
                <Icon name="plus" />
                {__('Create new label')}
              </span>
            </Label>
          )
        }
        {
          functionalityOptions((organization || { type: values.type }), values, this.props, this.studentRepliesFeature).map(functionality =>
            functionality.condition &&
            (this.showNewSectionStyle(
              <Divider section horizontal>{functionality.text}</Divider>,
              <Segment className={(this.studentRepliesFeature ? 'new ' : '') + (functionality.className || '')}>
                {functionality.values.map((field) => {
                  const subcomponents = (functionality.customComponent && functionality.customComponent.condition && functionality.customComponent.components) || field.components;
                  if (field.enabled && (!field.type || field.type === 'CHECKBOX')) {
                    return (<div>
                      <Form.Checkbox
                        toggle
                        data-value={!!values[field.value]}
                        key={field.text}
                        label={field.text}
                        name={field.value}
                        checked={!!values[field.value] || field.checked}
                        className={field.className || ''}
                        onClick={() => {
                          if (field.onClick) {
                            field.onClick(this);
                          } else {
                            this.onInputChange(null, { name: field.value, checked: !values[field.value] });
                          }
                        }}
                      />
                      {subcomponents && subcomponents.map((component) => {
                        if (component.type === 'GROUP') {
                          return (
                            <Form.Group style={component.styles}>
                              {component.subcomponents.map((subcomponent) => {
                                if (subcomponent.type === 'DROPDOWN') {
                                  return (
                                    <Form.Dropdown
                                      name={subcomponent.name}
                                      compact
                                      selection
                                      placeholder={subcomponent.placeholder}
                                      value={subcomponent.value(this)}
                                      disabled={subcomponent.disabled}
                                      options={subcomponent.options}
                                      onChange={(e, { value }) => subcomponent.onChange(this, { value })}
                                      {...subcomponent.props}
                                    />
                                  );
                                } else if (subcomponent.type === 'TEXT') {
                                  return (subcomponent.text);
                                } else if (subcomponent.type === 'CHECKBOX') {
                                  return (
                                    <Form.Checkbox
                                      toggle
                                      data-value={subcomponent.inverted ? !values[subcomponent.value] : !!values[subcomponent.value]}
                                      key={subcomponent.text}
                                      label={subcomponent.text}
                                      name={subcomponent.value}
                                      disableWithoutBanIcon={subcomponent.disableWithoutBanIcon}
                                      disabled={subcomponent.disabled}
                                      checked={subcomponent.inverted ? !values[subcomponent.value] : !!values[subcomponent.value] || subcomponent.checked}
                                      onClick={() => {
                                        if (subcomponent.onClick) {
                                          subcomponent.onClick(this);
                                        } else {
                                          this.onInputChange(null, { name: subcomponent.value, checked: !values[subcomponent.value] });
                                        }
                                      }}
                                    />
                                  );
                                }
                              })}
                            </Form.Group>
                          );
                        } else if (component.type === 'DIV') {
                          return (
                            <div id="daysOfWeek" style={component.styles}>
                              {component.subcomponents.map((subcomponent) => {
                                if (subcomponent.type === 'BUTTON') {
                                  return (
                                    <Button
                                      circular
                                      id={subcomponent.text}
                                      disabled={subcomponent.disabled}
                                      key={subcomponent.text}
                                      style={subcomponent.styles}
                                      onClick={() => subcomponent.onClick(this)}
                                    >
                                      <a style={subcomponent.linkStyles}>
                                        {subcomponent.text.charAt(0)}
                                      </a>
                                    </Button>
                                  );
                                }
                              })}
                            </div>
                          );
                        }
                      })
                      }
                    </div>);
                  } else if (field.type === 'LABEL') {
                    return field.condition && (
                      <div style={{display: 'flex', flexDirection: 'row', ...field.styles }}>
                        {field.icon && (
                          <Icon {...field.icon} />
                        )}
                        <div>{field.text}</div>
                      </div>
                    );
                  }

                  return (
                    <Popup
                      trigger={<div>
                        <Form.Checkbox
                          toggle
                          key={field.text}
                          label={field.text}
                          name={field.value}
                          checked={!!values[field.value]}
                          onClick={() => (field.onClick ? field.onClick(this, field) : this.onInputChange(null, { name: field.value, checked: !values[field.value] }))}
                          disabled
                          style={{ opacity: '.3' }}
                        />
                      </div>}
                      content={<span>{(!values.confMedia && values.confMessage) ? __('You cannot change this option if the send media option is disabled.') : __("You can't change this option if messages are disabled")}</span>}
                    />);
                })}
              </Segment>,
              null,
              { key: functionality.text })
            )
          )
        }
        <Divider />
        {this.props.children}
      </Form>
    );
  }
}
