import React from 'react';
import { inject, observer } from 'mobx-react';
import { graphql } from 'react-apollo';
import gql from 'graphql-tag';
import { Loader } from 'semantic-ui-react';

import { defaultRules } from './Constants';

import Controller from '../../components/Controller';
import OrganizationForm from '../Organization/Form';

import { __ } from '../../i18n';

@inject('store')
@graphql(gql`query OrganizationEditQuery ($id: ID!) {
  node(id: $id) @connection(key: "Organization", filter: ["id"]) {
    ... on Organization {
      id: dbId
      fullname
      username
      link
      color
      language
      country
      confMessage
      confMedia
      confCommitment
      confSurvey
      confCharge
      confReport
      confMessageStudent
      confReplyStudent
      confRepliesStudent
      confMediaStudent
      confMessageStaff
      confAdminList
      ntfStartTime
      ntfEndTime
      ntfWeekDaysArray
      organizationBanners {
        nodes {
          id
          bannerType
        }
      }
      type
      plan
      premiumPeriod
      isDemonstration
      monthlyMessagesCount
      monthlyMessageQuota
      timezone
      usage
      consultantId
      tags(limit: 400) {
        nodes {
          name
          id: dbId
        }
      }
      labels (limit: 100) {
        nodes {
          id: dbId
          title
          type
          color
          usedInMessages
        }
      }
      features {
        doNotDisturb
        labels
        deleteMessages
        editMessages
      }
    }
  }
}
`, {
  options: ownProps => ({
    name: 'OrganizationEditQuery',
    variables: {
      id: ownProps.params.organization_id
    }
  }),
  skip: ownProps => ownProps.store.currentUser.isMaster
})
@graphql(gql`query OrganizationEditMasterQuery ($id: ID!) {
    node(id: $id) @connection(key: "Organization", filter: ["id"]) {
      ... on Organization {
        id: dbId
        fullname
        username
        link
        color
        language
        country
        confMessage
        confMedia
        confCommitment
        confSurvey
        confCharge
        confReport
        confRecurrence
        confMessageStudent
        confReplyStudent
        confRepliesStudent
        confMediaStudent
        confMessageStaff
        confAdminList
        ntfStartTime
        ntfEndTime
        ntfWeekDaysArray
        organizationBanners {
          nodes {
            id
            bannerType
          }
        }
        permissions {
          hasAdmin
        }
        type
        plan
        premiumPeriod
        isDemonstration
        monthlyMessagesCount
        monthlyMessageQuota
        timezone
        usage
        consultantId
        collectionRule {
          nodes {
            id: dbId
            value
            interval
            dueDateInterval
          }
        }
        tags(limit: 400) {
          nodes {
            name
            id: dbId
          }
        }
        labels (limit: 100) {
          nodes {
            id: dbId
            title
            type
            color
            usedInMessages
          }
        }
        features {
          doNotDisturb
          labels
          deleteMessages
          editMessages
        }
      }
    }
    channels {
      nodes {
        id: dbId
        fullname
        picture {
          id: dbId
          uri
        }
      }
    }
  }
  `, {
  options: ownProps => ({
    name: 'OrganizationMasterEditQuery',
    variables: {
      id: ownProps.params.organization_id
    }
  }),
  skip: ownProps => !ownProps.store.currentUser.isMaster
})
@graphql(gql`mutation updateOrganization($updateOrganizationMutation: UpdateOrganizationInput!) {
  updateOrganization(input: $updateOrganizationMutation) {
    clientMutationId
  }
}`, {
  name: 'updateOrganization',
  options: {
    refetchQueries: ['OrganizationEditQuery', 'OrganizationEditMasterQuery', 'OrganizationNodeIdQuery', 'OrganizationNodeUsernameQuery', 'AppQuery']
  }
}
)
@graphql(gql`mutation createRule($createRule: CreateRuleInput!) {
  createRule(input: $createRule) {
    clientMutationId
  }
}`, {
  name: 'createRule',
  options: {
    refetchQueries: ['OrganizationEditMasterQuery', 'OrganizationEditQuery']
  }
}
)
@observer
export default class OrganizationEdit extends Controller {
  static fetchData({ store }) {
    store.app.title = __('Edit organization');
  }

  componentDidMount() {
    try {
      if (hj && this.props.store.currentOrganization.plan === 'FREE') {
        hj('trigger', 'configuracoesfree');
      }
    } catch (e) {
      console.error(e);
    }
  }

  request = (values) => {
    const { store, data, params, updateOrganization, createRule } = this.props;

    return updateOrganization({
      variables: {
        updateOrganizationMutation: {
          id: params.organization_id,
          ...values,
          defaultFeatures: undefined,
          labels: undefined,
          collectionRule: undefined
        }
      }
    }).then(async () => {
      const recurrenceBefore = data.node.confRecurrence;
      const recurrenceActivated = values.confRecurrence;
      if (!recurrenceBefore && recurrenceActivated) {
        await Promise.all(defaultRules(store.currentOrganization.id).map(rule => new Promise(async (resolve) => {
          const createResponse = await createRule({
            variables: {
              createRule: {
                organizationId: params.organization_id,
                value: rule.value,
                dueDateInterval: rule.dueDateInterval,
                interval: rule.interval
              }
            }
          });
          resolve();
        })));
      }
    }).then(() => {
      store.snackbar = { active: true, message: __('%s was saved', values.fullname), success: true };
    });
  }

  render() {
    const { data, params } = this.props;

    if ((data.loading && !data.node) || !data.node) return <Loader active />;
    const { fullname, username, link, color, language, country, confMessage, confMedia, confCommitment, confSurvey, confCharge,
      confReport, confRecurrence, confMessageStudent, confReplyStudent, confRepliesStudent, confMediaStudent, confMessageStaff, confAdminList,
      ntfStartTime, ntfEndTime, ntfWeekDaysArray, type, tags, plan, features, labels, timezone, usage, consultantId, collectionRule, organizationBanners, permissions } = data.node;
    let channels = [];

    if (data.channels) {
      channels = data.channels.nodes.map(node => ({
        key: node.id,
        text: node.fullname,
        value: node.id.toString(),
        image: { avatar: true, src: node.picture && node.picture.uri },
      }));

      channels = channels.concat({
        key: null,
        text: __('No consultant'),
        value: null
      });
    }

    return (
      <Controller
        id="OrganizationEdit"
        edit
        title={__('Edit organization')}
        form={OrganizationForm}
        values={{
          fullname,
          username,
          link,
          color,
          language,
          country,
          confMessage,
          confMedia,
          confCommitment,
          confSurvey,
          confCharge,
          confReport,
          confRecurrence,
          confMessageStudent,
          confReplyStudent,
          confRepliesStudent,
          confMediaStudent,
          confMessageStaff,
          confAdminList,
          ntfStartTime,
          ntfEndTime,
          ntfWeekDaysArray,
          type,
          plan,
          timezone,
          usage,
          consultantId,
          collectionRule
        }}
        loading={this.state.loading}
        errors={this.state.errors}
        onSubmit={this.onSubmit}
        formProps={{
          organization: {
            id: params.organization_id,
            tags: tags.nodes,
            labels: labels.nodes,
            type,
            features,
            organizationBanners,
            permissions
          },
          user: {
            isMaster: this.props.store.currentUser.isMaster
          },
          channels
        }}
        {...this.props}
      />
    );
  }
}
